<template>
  <div>
    <banner>
      <template v-slot:image>
        <img src="@/assets/images/guide_vs1_1.png" alt="" />
      </template>
      <template v-slot:content>Work Process</template>
    </banner>
    <breadcrumb-wrapper></breadcrumb-wrapper>
    <content-wrapper class="content-wrapper">
      <template v-slot:header>
        <h3 class="title_h2">디지털 교정</h3>
      </template>
      <template v-slot:content>
        <div style="text-align: center">
          <div class="mg_b_text_wrap">
            <div class="mg_b_text_01">임상자문의</div>
            <div class="mg_b_text_02">임상질의/응답</div>
            <div class="mg_b_text_03">1. 가공물접수</div>
            <div class="mg_b_text_04">2. 주문접수</div>
            <div class="mg_b_text_05-1">3-1. 디자인<br />임상자문 선택</div>
            <div class="mg_b_text_05-2">3-2. 디자인<br />임상자문 비선택</div>
            <div class="mg_b_text_06">4. 파일업로드</div>
            <div class="mg_b_text_07">5. 디자인승인</div>
            <div class="mg_b_text_08">6. 장치제작 및 배송</div>
            <div class="mg_b_text_09">치과</div>
            <div class="mg_b_text_10">MagicLine 포털</div>
            <div class="mg_b_text_11">디자인/생산센터</div>
          </div>
          <img src="@/assets/images/img-work-img-01-01.png" alt="" />
        </div>
      </template>
    </content-wrapper>
    <content-wrapper class="content-wrapper">
      <template v-slot:header>
        <h3 class="title_h2">임플란트 보철</h3>
      </template>
      <template v-slot:content>
        <div style="text-align: center">
          <div class="mg_b_text_wrap">
            <div class="mg_b_text_01">임상자문의</div>
            <div class="mg_b_text_02">임상질의/응답</div>
            <div class="mg_b_text_03">1. 가공물접수</div>
            <div class="mg_b_text_04">2. 주문접수</div>
            <div class="mg_b_text_05">3. 디자인</div>
            <div class="mg_b_text_06">4. 파일업로드</div>
            <div class="mg_b_text_07">5. 디자인승인</div>
            <div class="mg_b_text_08">6. 장치제작 및 배송</div>
            <div class="mg_b_text_09">치과</div>
            <div class="mg_b_text_10">MagicLine 포털</div>
            <div class="mg_b_text_12">생산센터</div>
          </div>
          <img src="@/assets/images/img-work-img-02-02.png" alt="" />
        </div>
      </template>
    </content-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Banner from '@/shared/Banner.vue';
import BreadcrumbWrapper from '@/shared/BreadcrumbWrapper.vue';
import ContentWrapper from '@/shared/ContentWrapper.vue';

export default defineComponent({
  components: { Banner, BreadcrumbWrapper, ContentWrapper }
});
</script>

<style scoped lang="scss">
@import './WorkProcessView.scss';
</style>
